import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  createdCustomerId: localStorage.getItem("customerIdReceivedFromState") || "",
  createdCustomerError: [],
  createdAddressId: localStorage.getItem("addressIdReceivedFromState") || "",
  getAddressDataState: [],
  createAddress: [],
  pricesDetailsState: [],
  pricesDetailsProductState: "",
  countryCodeState: localStorage.getItem("countryCodeFromState") || "",
  subscriptionDataState: [],
  subscriptionDataIdState: null,
  transactionDataState: "",
  epochSecondState: "",
  changePaymentMethodState: [],
  updateAddressDataState: [],
  getCustromerFromOrganizationIdState: [],
};

const paymentFlowSlice = createSlice({
  name: "paymentFlow",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getCustomerId(state, action) {
      state.createdCustomerId =
        action.payload === undefined ? "" : action.payload;
    },
    getCustomerError(state, action) {
      state.createdCustomerError =
        action.payload === undefined ? [] : action.payload;
    },
    getAddressId(state, action) {
      state.createdAddressId =
        action.payload === undefined ? "" : action.payload;
    },
    getAddressData(state, action) {
      state.getAddressDataState =
        action.payload === undefined ? [] : action.payload;
    },
    getPricesDetailsAction(state, action) {
      state.pricesDetailsState =
        action.payload === undefined ? [] : action.payload;
      state.pricesDetailsProductState =
        action.payload.product_name === undefined
          ? ""
          : action.payload.product_name;
    },
    getCountryCodeApiAction(state, action) {
      state.countryCodeState =
        action.payload === undefined ? "" : action.payload;
    },
    getSubscriptionDataAction(state, action) {
      state.subscriptionDataState =
        action.payload === undefined ? [] : action.payload;
      state.subscriptionDataIdState =
        action.payload.subscription_id === undefined
          ? ""
          : action.payload.subscription_id;
    },
    getTransactionDetails(state, action) {
      state.transactionDataState =
        action.payload === undefined ? "" : action.payload;
    },
    getEpochSecondAction(state, action) {
      state.epochSecondState =
        action.payload === undefined ? "" : action.payload;
    },
    changePaymentMethodAction(state, action) {
      state.changePaymentMethodState =
        action.payload === undefined ? [] : action.payload;
    },
    updateAddressAction(state, action) {
      state.updateAddressDataState =
        action.payload === undefined ? [] : action.payload;
    },
    getCustromerFromOrganizationIdAction(state, action) {
      state.getCustromerFromOrganizationIdState =
        action.payload === undefined ? [] : action.payload;
    },
  },
});

export default paymentFlowSlice.reducer;
export const {
  getCustomerId,
  getCustomerError,
  getAddressId,
  getAddressData,
  getPricesDetailsAction,
  getCountryCodeApiAction,
  getSubscriptionDataAction,
  getTransactionDetails,
  getEpochSecondAction,
  changePaymentMethodAction,
  updateAddressAction,
  getCustromerFromOrganizationIdAction,
} = paymentFlowSlice.actions;
