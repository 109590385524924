export const publicPaths = {
  home: '/',
  blogs: '/Blogs',
  detailBlog: '/detailed-view',
  sheduleCall: '/demo-call-form',
  cleanCode: '/clean-code',
  kotlinInfo:'/kotlin-can-be-a-friend',
  learnCode:'/learn-to-code',
  tryFree: '/auth-register',
  paymentCard: '/payment-card',
  howitwork: '/HowItWork',
  pricing: '/Pricing',
  career: '/Career',
  team: "/Team",
  login: "/login",
  register: "/register",
  DashboardHome: "/home",
  forgotPassword: "/forgotPassword",
  resetPassword: "/resetPassword",
};

export const authProtectedPaths = {
  home: "/home",
  dashboard: "/dashboard",
  ManagePermissions: "managepermissions",
  OrganisationStructure: 'organisationstructure',
  ManageTeam: "manageteam",
  MyAccount: "myaccount",
  SignOut: "signout",
  Settings: "/dashboard/settings",
  ChoosePlan: "/dashboard/choosePlan",
  Workspace: "/dashboard/builder?",
  ManageUserProjects: "/dashboard/manageUserProjects?"
};
