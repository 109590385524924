import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { defaultStyle } from '../../config/Config';

const CommentTextField = styled(TextField)(({ position}) => ({
  width: '398px',
  zIndex: 9999,
  position: 'absolute',
  background: '#EBEBEB',
  border: `1px solid ${defaultStyle.defaultColor}`,
  borderRadius: '5px',
  '& .css-os7428-MuiInputBase-root-MuiOutlinedInput-root': {
    height: '48px !important',
  },
  '@keyframes shake': {
    '0%': { transform: 'translateX(0)' },
    '20%': { transform: 'translateX(-10px)' },
    '40%': { transform: 'translateX(10px)' },
    '60%': { transform: 'translateX(-10px)' },
    '80%': { transform: 'translateX(10px)' },
    '100%': { transform: 'translateX(0)' },
  },
  animation: position?.open  ? 'shake 0.10s' : 'none',
  top: position.y,
  left: position.x - 30,
}));

export default CommentTextField;
