import { memo, useEffect } from "react";
import { Dialog, Box, Typography, Stack, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "../Iconify";
import { defaultStyle } from "../../config/Config";
import { dispatch, useSelector } from "../../redux/store";
import { getResponseStatus } from "../../redux/slice/response";
import { errorCodes } from "../../Middleware/errorCodes";

CustomModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  content: PropTypes.any,
  title: PropTypes.string,
  sx: PropTypes.any,
};

function CustomModal({ open, handleClose, content, title, sx }) {
  const { responseStatus } = useSelector((state) => state.response);
  useEffect(() => {
    if (responseStatus === errorCodes.OK.code) {
      handleClose();
      dispatch(getResponseStatus(""));
    }
  }, [responseStatus]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      padding={0}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        borderRadius: "6px",
        background: "tranparent",
        backdropFilter: "blur(2px)",
      }}
    >
      <Box
        className="form-modal"
        sx={{
          height: "auto",
          padding: 2,
          ...sx,
          backgroundColor: defaultStyle.primaryBackGround,
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={1}
        >
          <Typography variant="subtitle1">{title}</Typography>
          <IconButton onClick={handleClose}>
            <Iconify
              sx={{ width: "20px", height: "20px" }}
              icon={"icon-park-outline:close"}
            />
          </IconButton>
        </Stack>
        {content}
      </Box>
    </Dialog>
  );
}

export default memo(CustomModal);
