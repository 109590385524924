import { publicPaths, authProtectedPaths } from "../../routes/path";

export const Navconfig = [
  { title: "HOME", path: publicPaths.home },
  { title: "BLOGS", path: publicPaths.blogs },
  { title: "PRICING", path: publicPaths.pricing },
  { title: "HOW IT WORKS", path: publicPaths.howitwork },
  { title: "TEAM", path: publicPaths.team },
  { title: "CAREER", path: publicPaths.career },
];

export const SidbarConfig = [
  {
    title: "Dashboard",
    path: authProtectedPaths.dashboard,
    icon: "ph:layout-light",
  },
  {
    title: "Manage Profile",
    path: authProtectedPaths.ManagePermissions,
    icon: "ph:identification-card-light",
  },
  {
    title: "Organization Structure",
    path: authProtectedPaths.OrganisationStructure,
    icon: "ph:steps-light",
  },
  {
    title: "Manage Team",
    path: authProtectedPaths.ManageTeam,
    icon: "ph:users-three",
  },
  {
    title: "My Account",
    path: authProtectedPaths.MyAccount,
    icon: "ph:user-rectangle-light",
  },
  {
    title: "Settings",
    path: authProtectedPaths.Settings,
    icon: "ph:gear-light",
  },
];
