import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const RootStyle = styled("div")(() => ({
  right: "48%",
  bottom: "24%",
  zIndex: 99999,
  height: "100px",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
export default function CustomLoader() {
  return (
    <Box
      sx={{
        position: "relative",
        height: "29vh",
      }}
    >
      <RootStyle>
        <CircularProgress />
      </RootStyle>
    </Box>
  );
}
