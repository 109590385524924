import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const AssetTextfield = styled(TextField)({
    width: '72px',
    height: '34px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999', // Setting the border color to #999999
            borderWidth: '1px',
            borderRadius: '4px' // Or any specific value
        },
        '& input': {
            padding: '6px'  // Padding inside the input
        }
    }
});



export default AssetTextfield;
