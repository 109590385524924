import { AppBar } from "@mui/material";
import DesktopNavbar from "./DesktopNavbar";
import useResponsive from "../../hooks/useResponsive";
import MobileNavbar from "./MobileNavbar";
import { Navconfig } from "./Navconfig"

function Navbar() {
  const isDesktop = useResponsive("up", "md");
  return (
    <AppBar
      position="relative"
      sx={{
        height: "110px",
        color: "black",
        bgcolor: "white",
        boxShadow: 0,
        margin:"auto"
      }}
    >
      {isDesktop ? <DesktopNavbar /> : <MobileNavbar Config={Navconfig} />}
    </AppBar>
  );
}

export default Navbar;
