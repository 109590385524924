import { Card } from "@mui/material";
import PropTypes from "prop-types";

CustomRowCard.propTypes = {
  content: PropTypes.any,
  sx: PropTypes.object,
};
function CustomRowCard({ content, sx }) {
  return (
    <Card
      sx={{
        ...sx,
        background: "white",
        marginBottom: "1px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: 0,
        px: "12px",
        py:"12px",
        borderRadius: "5px",
        paddingLeft:"12px",
      }}
    >
      {content}
    </Card>
  );
}

export default CustomRowCard;
