import { styled } from "@mui/system";

const AssetModal = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  width: "553px",
  minHeight: "386px",
  borderRadius: "8px",
  boxShadow: "0 4px 10px 0px rgba(0, 0, 0, 0.1)",
  paddingTop: "19px",
  paddingBottom: "24px",
  paddingLeft: "16px",
  paddingRight: "16px",
  zIndex: 9999,
});

export default AssetModal;
