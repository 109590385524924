import { useRoutes, Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import MainLayout from "../layout/main/Index";

import AuthGuard from "../guard/Authguard";
import GuestGuard from "../guard/GuestGuard";
import { publicPaths, authProtectedPaths } from "./path";

const CleanCodeInfo = lazy(() => import("../components/homeDetails/CleanCode"));
const KotlinCoroutineInfo = lazy(
  () => import("../components/homeDetails/KotlinCoroutine")
);
const LearnCodeInfo = lazy(() => import("../components/homeDetails/LearnCode"));
const DetailedView = lazy(
  () => import("../components/blogDetails/DetailedView")
);
const TryFreeForm = lazy(
  () => import("../components/form/createForms/TryFreeForm")
);
const CardDetailForm = lazy(
  () => import("../components/form/createForms/CardDetailForm")
);
const SheduleCallForm = lazy(
  () => import("../components/form/createForms/SheduleCallForm")
);

const Blogs = lazy(() => import("../pages/Blogs"));
const Home = lazy(() => import("../pages/Home"));
const HowItWork = lazy(() => import("../pages/HowItWork"));
const Pricing = lazy(() => import("../pages/Pricing"));
const Career = lazy(() => import("../pages/Career"));
const Team = lazy(() => import("../pages/Team"));
const NotFound = lazy(() => import("../pages/NotFound"));

const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const DashboardLayout = lazy(() => import("../layout/dashboard"));
const Dashboard = lazy(() => import("../layout/dashboard/Dashboard"));
const ManageUserProjects = lazy(
  () => import("../pages/dashboard/ManageUserProjects")
);
const Primary = lazy(() => import("../workspace/editor/Primary"));
const Settings = lazy(() => import("../pages/dashboard/Settings"));
const ChoosePlan = lazy(() => import("../pages/dashboard/ChoosePlan"));

export default function Router() {
  return useRoutes([
    {
      path: `${publicPaths.login}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <GuestGuard>
            <Login />
          </GuestGuard>
        </Suspense>
      ),
    },

    {
      path: `${publicPaths.register}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
    
          <Register />
        </Suspense>
      ),
    },

    {
      path: `${publicPaths.forgotPassword}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
    
          <ForgotPassword />{" "}
        </Suspense>
      ),
    },

    {
      path: `${publicPaths.resetPassword}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
    
          <ResetPassword />{" "}
        </Suspense>
      ),
    },
    {
      path: `${authProtectedPaths.dashboard}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </Suspense>
      ),
      children: [
        {
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <Dashboard />
            </Suspense>
          ),
          index: true,
        },
        { path: `${authProtectedPaths.Workspace}`, element: <Primary /> },
      ],
    },
    {
      path: `${authProtectedPaths.ManageUserProjects}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard>
            <ManageUserProjects />
          </AuthGuard>
        </Suspense>
      ),
    },
    {
      path: `${authProtectedPaths.Settings}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard>
            <Settings />
          </AuthGuard>
        </Suspense>
      ),
    },
    {
      path: `${authProtectedPaths.ChoosePlan}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard>
            <ChoosePlan />
          </AuthGuard>
        </Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
    
          <NotFound />{" "}
        </Suspense>
      ),
      children: [
        {
          path: "404",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <NotFound />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <Navigate to="/404" replace />
            </Suspense>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    {
      path: "/",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
    
          <MainLayout />
        </Suspense>
      ),
      children: [
        {
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <Home />
            </Suspense>
          ),
          index: true,
        },
        {
          path: `${publicPaths.blogs}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <Blogs />
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.detailBlog}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <DetailedView />{" "}
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.sheduleCall}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <SheduleCallForm />
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.tryFree}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <TryFreeForm />{" "}
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.cleanCode}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <CleanCodeInfo />{" "}
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.learnCode}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <LearnCodeInfo />
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.kotlinInfo}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <KotlinCoroutineInfo />
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.paymentCard}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <CardDetailForm />
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.howitwork}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <HowItWork />{" "}
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.career}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <Career />{" "}
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.pricing}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <Pricing />
            </Suspense>
          ),
        },
        {
          path: `${publicPaths.team}`,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
        
              <Team />
            </Suspense>
          ),
        },
      ],
    },
  ]);
}
