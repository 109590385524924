import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  Stack,
  Menu,
  IconButton,
} from "@mui/material";
import { Button, Iconify, NavList } from "../../components";
import logo from "../../assets/synsta-io-logo.d72b5685.png";
import PropTypes from "prop-types";
import {publicPaths} from "../../routes/path"

MobileNavbar.propTypes = {
    Config: PropTypes.any
  };

function MobileNavbar({Config}) {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <img
        src={logo}
        alt="img"
        style={{ height: 60, width: 160, color: "blue" }}
      />
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={handleMenuClick}
      >
        <Iconify icon={"dashicons:menu-alt3"} />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        sx={{ width: "300px" }}
      >
        {Config?.map((item,index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <NavList item={item} />
            </ListItemButton>
          </ListItem>
        ))}
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
          spacing={1}
        >
          <Button
            Content="Sign Up"
            handleClick={() => navigate(`${publicPaths.register}`)}
          />
          <Button
            Content="Sign In"
            handleClick={() => navigate(`${publicPaths.login}`)}
          />
        </Stack>
      </Menu>
    </Stack>
  );
}

export default MobileNavbar;
