import {
  Checkbox,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import { useEditor } from "@grapesjs/react";
import PropTypes from "prop-types";
import Iconify from "../Iconify";
import { useState } from "react";
import pencil from "../../assets/iconamoon_edit-thin.svg";
import StyledImageBox from "./ImageBox";
import { dispatch } from "../../redux/store";
import { getAssetDetail } from "../../redux/slice/workspace";

AssetBox.propTypes = {
  dragStart: PropTypes.any,
  dragStop: PropTypes.any,
  block: PropTypes.any,
};
function AssetBox({ dragStart, dragStop, block }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const editor = useEditor();
  const [changeName, setChangeName] = useState(false);
  const [name, setName] = useState("");
  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlchangeaction = (item) => {
    setName(item.getLabel());
    setChangeName(true);
  };
  const clearInput = () => {
    setName("");
    setChangeName(false);
  };
  const handleotherOptions = (option, img, e) => {
    if (option === "Edit") {
      const data = {
        id: img.getId(),
        type: img.getCategoryLabel(),
        imageUrl: img.getContent().src
          ? img.getContent().src
          : img.getContent(),
        imageName: img.getLabel(),
        file: img._previousAttributes.attributes.file,
      };
      dispatch(getAssetDetail(data));
      setAnchorEl(null);
    }
    if (option === "Delete") {
      editor.BlockManager.remove(img.getId());
      setAnchorEl(null);
    }
    if (option === "Rename" && e?.key === "Enter" && name.trim() !== "") {
      img.set("label", name);
      clearInput();
      setAnchorEl(null);
    }
  };
  const options = ["Edit", "Download", "Delete"];
  return (
    <>
      <StyledImageBox
        draggable
        onDragStart={(ev) => dragStart(block, ev.nativeEvent)}
        onDragEnd={() => dragStop(false)}
        imageUrl={block.getContent().src}
        filter={block.getContent()?.style?.filter}
      >
        <Stack
          direction={"row"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          width={"100%"}
        >
          <IconButton sx={{ padding: "0px !important" }} onClick={handleMenu}>
            <Iconify
              icon={"charm:menu-kebab"}
              sx={{ width: "20px", height: "20px" }}
            />
          </IconButton>
          <Menu
            sx={{ width: "281px !imortant" }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <MenuItem>
              <Stack
                direction={"row"}
                alignItems={"center"}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <>
                  {changeName ? (
                    <Stack direction={"row"} alignItems={"center"}>
                      <TextField
                        size="small"
                        width="fullWidth"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#999999",
                              borderWidth: "1px",
                              borderRadius: "4px",
                            },
                          },
                        }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onKeyUp={(e) => handleotherOptions("Rename", block, e)}
                      />
                      <IconButton onClick={clearInput}>
                        <Iconify
                          icon={"fluent-emoji-high-contrast:cross-mark"}
                        />
                      </IconButton>
                    </Stack>
                  ) : (
                    <Typography
                      variant="sideBarTitle"
                      sx={{ color: "#1A1A1A" }}
                    >
                      {block.getLabel()}
                    </Typography>
                  )}
                </>
                {!changeName && (
                  <IconButton onClick={() => handlchangeaction(block)}>
                    <img
                      src={pencil}
                      style={{ width: "24px", height: "24px" }}
                    />
                  </IconButton>
                )}
              </Stack>
            </MenuItem>
            {options.map((item, id) => (
              <MenuItem
                key={id}
                onClick={() => handleotherOptions(item, block)}
              >
                {item}
              </MenuItem>
            ))}
            <MenuItem>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={"100%"}
                height={"30px"}
              >
                <Typography>Show In all projects</Typography>
                <Checkbox />
              </Stack>
            </MenuItem>
          </Menu>
        </Stack>
      </StyledImageBox>
    </>
  );
}

export default AssetBox;
