import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useFormContext, Controller } from "react-hook-form";
import { Select, MenuItem } from "@mui/material";
import { defaultStyle } from "../../config/Config";


export const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#999999",
      borderWidth: "1px",
      borderRadius: "5px",
    },
    "& select": {
      padding: "6px",
    },
  },
});

ModalSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  onDataChange: PropTypes.func,
  sx: PropTypes.object,
  IsBlackBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue:PropTypes.string
};

export default function ModalSelectField({
  name,
  options,
  onDataChange,
  sx,
  disabled,
  defaultValue,
  ...other
}) {
  const { control, register } = useFormContext();


  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledSelect
        disabled={disabled}
          size="small"
          color="primary"
          {...field}
          fullWidth
          error={!!error}
          {...register(name, {
            onChange: (e) => {
              if (onDataChange) {
                onDataChange(e);
              }
            },
          })}
          sx={{
            ...sx,
            backgroundColor: defaultStyle.whiteBackground,
            color: defaultStyle.defaultColor,
            fontSize: "14px",
            padding: "4px 14px 4px 0",
            height: "2.5rem",
            border:"1px solid black"
          }}
          {...other}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      )}
    />
  );
}
