import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton, TextFields } from "../../../components";
import { useForm } from "react-hook-form";
import { Stack, Typography, Grid } from "@mui/material";
import { FormProvider as Form } from "../../../components/form/FormProvider";
import { publicPaths } from "../../../routes/path";


function SheduleCallFrom() {
  const navigate = useNavigate();

  const defaultValues = {
    fullName: "",
    email: "",
    phoneNumber: null,
    companyName: "",
    jobTitle: "",
    comments: "", 
  };
  const requestCallSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().email().required("email is required"),
    phoneNumber: Yup.number().typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .max(9999999999, "phone number must have 10 digit")
    .min(1000000000, "phone number must have 10 digit")
    .integer("A phone number can't include a decimal point")
    .required('A phone number is required'),
    companyName: Yup.string().required("Company Name is required"),
    jobTitle: Yup.string().required("Job Title is required"),
    comments: Yup.string(),
  });
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(requestCallSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;
  const handleRequestCall = () => {
    navigate(publicPaths.pricing)
  };

  return (
    <Grid
      sx={{
        width: "70%",
        textAlign: "center",
        padding: 4,
        margin: "0 auto",
      }}
      container spacing={3}
    >
      <Grid direction="column" item  xs={12} md={4} sx={{width:"300px"}}>
        <Typography variant="h3" color="grey">Request a Call</Typography>
        <Typography variant="h4" color="grey">Schedule a call with our team to discuss custom solutions for your brand or business.</Typography>
      </Grid>
      <Grid direction="column"  item  xs={12} md={8}>
      <Form  methods={methods} onSubmit={handleSubmit(handleRequestCall)}>
        <Stack spacing={1.5} margin={1}>
          <Grid container spacing={2}>
          <Grid direction="column" item  xs={12} md={12}>
            <TextFields
              sx={{ padding: "3px 3px", borderRadius: "7px" }}
              name="fullName"
              label="Full Name"
              type="text"
            />
          </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid direction="column" item  xs={12} md={6}>
            <TextFields
              sx={{ padding: "3px 3px", borderRadius: "7px" }}
              name="email"
              label="Email"
              type="text"
            />
            </Grid>
            <Grid direction="column" item  xs={12} md={6}>
            <TextFields
              sx={{ padding: "3px 3px", borderRadius: "7px" }}
              name="phoneNumber"
              label="Phone Number"
              type="number"
            />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid direction="column" item  xs={12} md={6}>
            <TextFields
              sx={{ padding: "3px 3px", borderRadius: "7px" }}
              name="companyName"
              label="Company Name"
              type="text"
            />
            </Grid>
            <Grid direction="column" item  xs={12} md={6}>
            <TextFields
              sx={{ padding: "3px 3px", borderRadius: "7px" }}
              name="jobTitle"
              label="Job Title"
              type="text"
            />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid direction="column" item  xs={12} md={12}>
          <TextFields
            sx={{ padding: "3px 3px", borderRadius: "7px" }}
            name="comments"
            label="Tell us about your Company, Project and Business Plan"
            multiline
            rows={4}
          />
          </Grid>
          </Grid>
          <LoadingButton
            title={"Submit"}
            type="submit"
            sx={{
              padding: "8px",
              borderRadius: "5px !important",
            }}
          />
        </Stack>
      </Form>
      </Grid>
    </Grid>
  );
}

export default SheduleCallFrom;
