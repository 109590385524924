import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";
import { defaultStyle } from "../../config/Config";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

SelectTextfields.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  source: PropTypes.any,
  disable: PropTypes.bool,
  onDataChange: PropTypes.func,
  multiple: PropTypes.bool,
};

export default function SelectTextfields({
  name,
  children,
  disable,
  onDataChange,
  ...other
}) {
  const { control, register } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          size="small"
          sx={{
            backgroundColor: defaultStyle.whiteBackground,
            borderRadius: "5px",
            padding: "0",
            "& .MuiInputBase-root.Mui-focused fieldset": {
              border: `1px solid ${defaultStyle.defaultColor}`,
            },

            "& .MuiInputBase-root:not(.Mui-focused) fieldset": {
              border: `1px solid ${defaultStyle.textFieldGreyBorder}`,
            },
            "& .MuiList-root.MuiMenu-list": {
              border: `1px solid ${defaultStyle.defaultColor}`,
            },
          }}
          InputProps={{
            sx: {
              backgroundColor: defaultStyle.whiteBackground,
              color: defaultStyle.defaultColor,
              height: "2.5rem",
              fontSize: "14px",
              padding: "4px 12px 4px 0",
              "& .MuiList-root.MuiMenu-list": {
                border: `1px solid ${defaultStyle.textFieldGreyBorder}`,
              },
            },
          }}
          SelectProps={{
            native: false,
            disabled: disable,
            IconComponent: () => (
              <Icon
                icon="bi:chevron-down"
                color="#999"
                width="24"
                height="24"
              />
            ),
          }}
          error={!!error}
          {...register(name, {
            onBlur: (e) => {
              if (onDataChange) {
                onDataChange(e);
              }
            },
          })}
          helperText={error?.message}
          disabled={disable}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
