import { useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import Iconify from "../Iconify";
import { styled } from "@mui/material/styles";
import { defaultStyle } from "../../config/Config";

FileUploadButton.propTypes = {
  title: PropTypes.string,
  handleFileChange: PropTypes.any,
  icon:PropTypes.bool,
  accept:PropTypes.string
};
export default function FileUploadButton({ title, handleFileChange,icon,accept }) {
  const pikref = useRef(null);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const uploadButtonStyle = {
    width: "108px",
    height: "34px",
    marginLeft: "6px",
    background: defaultStyle.buttonColor,
    borderRadius: "5px 5px 5px 5px ",
  };
  const handleFile = (event) => {
    handleFileChange(event);
  };
  return (
    <Button
      disableElevation
      component="label"
      variant="contained"
      startIcon={icon && <Iconify icon={"ph:plus"} style={{ fontSize: "15px" }} />}
      sx={{
        ...uploadButtonStyle,
        "&:hover": {
          background: defaultStyle.buttonColor,
        },
      }}
    >
      {title}
      <VisuallyHiddenInput
      
        type="file"
        ref={pikref}
        accept={accept}
        onChange={(e) => handleFile(e)}
      />
    </Button>
  );
}
