import { ListItem, Link, ListItemButton } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Iconify from "./Iconify";

ListItems.propTypes = {
  Index: PropTypes.number,
  item: PropTypes.object,
  style: PropTypes.object,
  selectedIndex: PropTypes.number,
  handleClick: PropTypes.func,
};

function ListItems({ Index, item, style, selectedIndex, handleClick }) {
  const theme = useTheme();
  return (
    <>
      <ListItem key={Index} disablePadding>
        <ListItemButton
          sx={{
            display: "flex",
            height: "44px",
            justifyContent: "space-between",
            alignItems: "center",
            borderLeft: "4px solid transparent ",
            "&.Mui-selected": {
              backgroundColor: "#FCE5DE",
              borderLeft: "4px solid black ",
              color: theme.palette.primary.main,
              fontWeight: 600,
            },
            "&.Mui-selected:hover": { backgroundColor: "#FCE5DE" },
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: "#FCE5DE",
              boxShadow: 0,
            },
          }}
          selected={selectedIndex === Index}
          onClick={handleClick}
        >
          <Iconify
            icon={item.icon}
            sx={{ width: "1.5rem", height: "1.5rem", marginRight: ".75rem" }}
          />
          <Link
            underline="none"
            sx={{
              ...style,

              "&:hover": {
                color: theme.palette.primary.main,
                boxShadow: 0,
              },
              color: "black",
            }}
          >
            {item.title}
          </Link>
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default ListItems;
