import { memo, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { dispatch } from "../redux/store";
import {
  getCurrentDatabseName,
  getTableID,
  getTableRelation,
  getTableViewID,
} from "../redux/slice/dataBase";
import { useSelector } from "../redux/store";
import icon from "../assets/carbon_column.svg";
import useMenus from "../hooks/useMenus";
import Iconify from "./Iconify";
import { getBlockCategory, getBlockName } from "../redux/slice/workspace";

CustomAccordion.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.func,
  data: PropTypes.array,
  title: PropTypes.string,
  index: PropTypes.number,
  editor: PropTypes.any,
};
function CustomAccordion({ open, setOpen, data, title, index, editor }) {
  const [ListOfChild, setListOfChild] = useState([]);
  const { getAnchorEl, isOpen, handleOpen, handleClose } = useMenus();
  const { tables } = useSelector((state) => state.dataBase);
  const padding = 0;
  const [id, setId] = useState("");
  const handleChild = (list, id) => {
    setListOfChild([...list]);
    setId((pre) => (pre === id ? "" : id));
  };
  const TableBasedonDatabse = data.filter(
    (item) => item?.databseName === title
  );
  const choseoptionfromMenu = (option, id) => {
    if (option === "Edit Table") {
      setOpen({
        ...open,
        tableModal: true,
      });
      dispatch(getTableID(id));
      setId("");
    }
    if (option === "Create API") {
      setOpen({
        ...open,
        apiModal: true,
      });
    }
    if (option === "Create Graph") {
      setOpen({
        ...open,
        dataVisualisation: true,
      });
    }
    if (option === "Create Form") {
      const findTableDetail = data.find((item) => item.id === id);
      console.log(findTableDetail, "findTableDetail");
      const fff = findTableDetail.listofClounms.map((item) => item.ColumnName);
      console.log(fff, "fff");
      localStorage.removeItem("database");

      dispatch(getBlockName(findTableDetail.tableName));
      const htmlcss = (
        <div className="form-block">
          {fff.map((item, index) => (
            <div key={index} className="form-field">
              <label>{item.columnName}</label>
              <input type="text" name={item.columnName} value={"sdksad;lk"} />
            </div>
          ))}
        </div>
      );
      dispatch(getBlockCategory("Backend-Components"));
      editor.getWrapper().append(htmlcss);
      dispatch(getBlockName(findTableDetail.tableName));
    }
    if (option === "Import Data") {
      setOpen({
        ...open,
        importData: true,
      });
    }
    if (option === "List View") {
      const data = tables.find((item) => item.id === id);
      if (data?.ForeignKeyDetail.length !== 0) {
        const filteredArray = tables.filter((obj1) =>
          data.ForeignKeyDetail.some(
            (obj2) => obj1.tableName === obj2.ForeignKeyTable
          )
        );
        const linkedList = [{ ...data }, ...filteredArray];
        dispatch(getTableRelation(linkedList));
      }
    }
    if (option === "Table View") {
      dispatch(getTableViewID(id));
    }
  };
  const optionsUpload = [
    "Edit Table",
    "Create API",
    "Import Data",
    "Create Form",
    "Create Graph",
    "List View",
    "Table View",
  ];
  return (
    <Box
      sx={{ width: "100%", marginTop: "16px", display: title === "" && "none" }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <IconButton
          sx={{ padding: padding, display: data?.length === 0 && "none" }}
          onClick={() =>
            setOpen({
              ...open,
              tableId: open.tableId !== "" ? "" : index,
            })
          }
        >
          <Iconify
            sx={{ width: "20px", height: "20px" }}
            icon={
              open.tableId === index
                ? "ic:baseline-expand-more"
                : "lets-icons:expand-right"
            }
          />
        </IconButton>
        <IconButton
          sx={{ padding: padding }}
          onClick={() => {
            setOpen({
              ...open,
              tableModal: true,
            });
            dispatch(getCurrentDatabseName(title));
          }}
        >
          <Iconify
            sx={{ width: "16px", height: "16px" }}
            icon={"material-symbols-light:database-outline"}
          />
        </IconButton>

        <Typography variant="teammember" sx={{ padding: padding }}>
          {title}
        </Typography>
        <IconButton sx={{ padding: padding }}>
          <Iconify
            sx={{ width: "20px", height: "20px" }}
            icon={"ph:dots-three-outline-vertical-fill"}
          />
        </IconButton>
      </Stack>
      {open.tableId === index &&
        TableBasedonDatabse?.map((lis, Index) => (
          <Stack key={Index} direction={"column"} alignItems={"center"}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ position: "relative" }}
            >
              <IconButton
                disabled={!lis.listofClounms}
                onClick={() => handleChild(lis.listofClounms, Index)}
                sx={{ display: lis?.listofClounms?.length === 0 && "none" }}
              >
                <Iconify
                  icon={
                    id !== Index
                      ? "lets-icons:expand-right"
                      : "ic:baseline-expand-more"
                  }
                />
              </IconButton>
              <IconButton sx={{ padding: padding }}>
                <Iconify
                  sx={{ width: "16px", height: "16px" }}
                  icon={"material-symbols-light:database-outline"}
                />
              </IconButton>
              <Typography variant="teammember" sx={{ margin: "0px" }}>
                {lis?.tableName}
              </Typography>

              <IconButton
                sx={{ padding: padding }}
                onClick={handleOpen("menu1")}
              >
                <Iconify
                  sx={{ width: "20px", height: "20px" }}
                  icon={"ph:dots-three-outline-vertical-fill"}
                />
              </IconButton>
              <Menu
                id="menu-1"
                anchorEl={getAnchorEl("menu1")}
                keepMounted
                open={isOpen("menu1")}
                onClose={handleClose("menu1")}
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
              >
                {optionsUpload.map((list, Index) => (
                  <MenuItem
                    key={Index}
                    onClick={() => choseoptionfromMenu(list, lis.id)}
                  >
                    {list}
                  </MenuItem>
                ))}
              </Menu>
            </Stack>
            <Box sx={{ width: "100%" }}>
              {id === Index &&
                ListOfChild?.map((value, ind) => (
                  <Stack
                    key={ind}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    width={"100%"}
                    margin={"8px"}
                  >
                    <IconButton sx={{ padding: padding }}>
                      <img
                        src={icon}
                        style={{ width: "18px", height: "18px" }}
                      />
                    </IconButton>
                    <Typography variant="teammember" sx={{ margin: "0px" }}>
                      {value?.ColumnName?.columnName}
                    </Typography>
                  </Stack>
                ))}
            </Box>
          </Stack>
        ))}
    </Box>
  );
}

export default memo(CustomAccordion);
