import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { SubmitButton } from "../../components";
import { useNavigate } from "react-router-dom";
import CustomCard from "../CustomCard";
import { publicPaths } from "../../routes/path";

BlogCard.propTypes = {
  blogcontent: PropTypes.any,
  title: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  imageUrl: PropTypes.any,
};
function BlogCard({ title, author, date, blogcontent, imageUrl }) {
  const navigate = useNavigate();

  return (
    <CustomCard
      content={
        <Card sx={{ maxWidth: 950, margin: "auto" }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <img src={imageUrl} alt="Blog" style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  gutterBottom
                >
                  {author} - {date}
                </Typography>
                <Box mt={2}>
                  <Typography variant="body1">
                    {blogcontent.slice(0, 150)}
                  </Typography>
                </Box>
                {blogcontent.length > 150 && (
                  <SubmitButton
                    sx={{ marginTop: "50px" }}
                    handleClick={() => {
                      navigate(publicPaths.detailBlog, {
                        state: {
                          content: blogcontent,
                          title: title,
                          author: author,
                          imageUrl: imageUrl,
                        },
                      });
                    }}
                    Content="Read More"
                  ></SubmitButton>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
    />
  );
}

export default BlogCard;
