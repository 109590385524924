import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography, CardMedia, Link } from "@mui/material";


function DetailedView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  if (!state) {
    return null;
  }

  const handlePreviousPage = () => {
    navigate(-1);
  };

  return (
  <>
  <Link variant="outlined" onClick={handlePreviousPage}
  sx={{marginLeft: "30px" }}>
    Go Back
  </Link>
    <Container         
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}>
  <CardMedia
    sx={{ maxWidth: 500, margin: "auto" }}
    component="img"
    alt={state.title}
    image={state.imageUrl}
  />
      <Typography variant="h4" sx={{
            color: "#6b6464",
            padding:"16px 0px"
          }}>
        {state.title}
      </Typography>
      <Typography variant="p" sx={{
            fontSize: "25px",
            color: "grey",
            padding: "16px 0px"
          }}>
        {state.author}
      </Typography>
      <Typography variant="body1" sx={{
            fontSize: "20px",
            lineHeight: "30px",
            color: "grey",
            padding: "16px 0px"
          }}>
        {state.content}
      </Typography>
    </Container>
    </>
  );
}

export default DetailedView;
