import React from 'react'
import { Typography, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { publicPaths } from '../../../routes/path';
import { defaultStyle } from "../../../config/Config";


  const List = [
    "When we first glance at something new, we tend to loose attention and most of the times we ignore.",
    "If that same new object is seen by a child, he/she gets curious and applies even more attention to it.",
    "If you have ever accompanied a kid, you might have observed the set of questions, which can boggle your mind.",
    "That clearly depicts the urge of learning, which unfortunately has been lost in majority.",
    "Same thing happens even when we call ourselves experienced or professionals. Unfortunately, we tend to ignore the stuff which is not recorded in our brain before.",
    "I did the same thing with Kotlin Coroutine. I saw and ignored, so quickly that it never existed.",
  ];

 const KotlineContent = () => { 
    const color = defaultStyle.secondaryColor;

    const navigate = useNavigate();
  
  const handleClick = (path) => {
      navigate(path)
    };

  return(
    <>
            <Typography variant="title">
              Kotlin Coroutine can be a friend
            </Typography>
            {List.map((item) => (
              <ListItem
                key={item}
                disablePadding
                sx={{ display: "list-item", color: "grey", padding: 1 }}
              >
                {item}
              </ListItem>
            ))}
            <Typography
              sx={{ cursor: "pointer" }}
              variant="inherit"
              color={color}
              onClick={()=>handleClick(publicPaths.kotlinInfo)}
            >
              Read More...
            </Typography>
          </>
  )}
  
  export default KotlineContent
