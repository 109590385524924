import { useState } from "react";

function useMenus() {
  const [anchors, setAnchors] = useState({});

  const handleOpen = (menuId) => (event) => {
    setAnchors((prevAnchors) => ({
      ...prevAnchors,
      [menuId]: event.currentTarget,
    }));
  };

  const handleClose = (menuId) => () => {
    setAnchors((prevAnchors) => ({
      ...prevAnchors,
      [menuId]: null,
    }));
  };

  return {
    getAnchorEl: (menuId) => anchors[menuId],
    isOpen: (menuId) => Boolean(anchors[menuId]),
    handleOpen,
    handleClose,
  };
}

export default useMenus;
