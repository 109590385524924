export const refreshTokenMutation = (refreshToken) => {
  const variables = {
    refreshToken: refreshToken,
  };
  return variables;
};

export const loginMutation = (username, password, sso, sig) => {
  const variables = {
    username: username,
    password: password,
    sso: sso,
    sig: sig,
  };
  return variables;
};

export const ssoRedirectUrlMutation = (sso, sig, id) => {
  const variables = {
    sso: sso,
    sig: sig,
    id: id,
  };
  return variables;
};

export const registerMutation = (
  firstName,
  lastName,
  email,
  password,
  generatedOTP
) => {
  const variables = {
    request: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      otp: generatedOTP,
    },
  };
  return variables;
};
export const createCustomerMutation = (
  email,
  customerName,
) => {
  const variables = {
    request: {
      email: email,
      name: customerName,
    },
  };
  return variables;
};

export const logoutMutation = (tokenId) => {
  const variables = {
    tokenId: tokenId,
  };
  return variables;
};

export const emailExistsMutation = (jsonData) => jsonData;

export const completeResetPasswordMutation = (email, otp, newPassword) => {
  const variables = {
    email: email,
    otp: otp,
    newPassword: newPassword,
  };
  return variables;
};

export const changePasswordMutation = (oldPassword, newPassword) => {
  const variables = {
    username: localStorage.getItem("userEmail"),
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
  return variables;
};

export const createTeamMemberMutation = (memberData) => {
  memberData.username = memberData.email;
  const variables = {
    request: {
      username:  memberData?.username,
      firstName: memberData?.firstName,
      lastName: memberData?.lastName,
      email: memberData?.email,
      profileId: memberData?.profileId,
      mobile: memberData?.mobile
  }
  };
  return variables;
};
export const basePaddleUrl = (paddleUrl) =>
  `https://subscription-dev.synsta.io/api/paddle/${paddleUrl}`;

export const createProfileMutation = (
  profileName,
  permissionIds,
) => {
  const variables = {
    profile: {
        name:  profileName,
        permissionIds:permissionIds
        
    },
  };
  return variables;
};