import { Card } from "@mui/material";
import { useSelector } from "../redux/store";
import { CustomLoader } from "../components/index";
import PropTypes from "prop-types";

CustomCard.propTypes = {
  content: PropTypes.any,
  sx: PropTypes.object,
};
function CustomCard({ content, sx }) {
  const { isLoading } = useSelector((state) => state.response);
  return (
    <Card
      sx={{
        ...sx,
        width: "100%",
        boxShadow: 0,
        py: 2,
        px: 2,
        borderRadius: 1,
        paddingLeft: "12px",
      }}
    >
      {isLoading ? <CustomLoader /> : <> {content}</>}
    </Card>
  );
}

export default CustomCard;
