import React, { useState, useEffect } from "react";
import {
  Stack,
  IconButton,
  AvatarGroup,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { getFollowUserId } from "../../redux/slice/workspace";
import { dispatch } from "../../redux/store";

TeamCollaboration.propTypes = {
  users: PropTypes.any,
  currentDisplayName: PropTypes.any,
  leftUsers: PropTypes.any,
};
function TeamCollaboration({ users, currentDisplayName, leftUsers }) {
  const [updatedUsersList, setUpdatedUsersList] = useState(users ? users : []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const otherMembers = window.localStorage.getItem("otherMembers");

  useEffect(() => {
    if (users) {
      if (leftUsers !== "") {
        const updatedList = JSON?.parse(otherMembers)?.filter(
          (item) => item.session !== leftUsers
        );
        setUpdatedUsersList(updatedList);
      } else {
        const ListOfUsers = JSON?.parse(otherMembers) || [];
        setUpdatedUsersList(ListOfUsers);
      }
    } else {
      setUpdatedUsersList([]);
    }
  }, [users, leftUsers]);

  const handleClick = (event) => {
    if (updatedUsersList.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFollowrequest = (id) => {
    dispatch(getFollowUserId(id));
    localStorage.setItem("senderID", id);
    handleClose();
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls="user-menu"
        aria-haspopup="true"
      >
        {updatedUsersList === undefined || updatedUsersList?.length === 0 ? (
          <Avatar
            sx={{
              width: 32,
              height: 32,
            }}
            alt={currentDisplayName}
            src={currentDisplayName}
          />
        ) : (
          <AvatarGroup max={2} total={updatedUsersList.length + 1}>
            {users?.map((user, index) => (
              <Avatar key={index} alt={user} src={user} />
            ))}
          </AvatarGroup>
        )}
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {updatedUsersList?.length > 0 &&
          updatedUsersList?.map((user, index) => (
            <MenuItem
              key={index}
              sx={{
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
            >
              <Stack
                width={"10vw"}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="teammember">
                  {" "}
                  {user?.displayName}
                </Typography>
                <Typography
                  variant="teammember"
                  color={"#EC673D"}
                  onClick={() => handleFollowrequest(user?.id)}
                >
                  {localStorage.getItem("senderID") === user?.id
                    ? "following"
                    : "follow"}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

export default TeamCollaboration;
