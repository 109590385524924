import { styled } from "@mui/system";

const StyledImageBox = styled("div")(({ imageUrl, filter }) => ({
  width: "153px",
  height: "88px",
  borderRadius: "5px",
  background: "#999999",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  padding: "11px 8px",
  marginTop: "4px",
  filter: filter && filter,
}));

export default StyledImageBox;
