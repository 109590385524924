import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, Typography } from "@mui/material";
import { defaultStyle } from "../../config/Config";

TextFields.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  InputLabelProps: PropTypes.string,
  onDataChange: PropTypes.func,
  sx: PropTypes.object,
  IsBlackBorder: PropTypes.bool,
};
export default function TextFields({
  name,
  label,
  type,
  onDataChange,
  IsBlackBorder,

  sx,
  ...other
}) {
  const { control, register } = useFormContext();
  const styleBlackBorder = {
    "& .MuiInputBase-root.Mui-focused fieldset": {
      border: `1px solid ${defaultStyle.defaultColor}`,
    },
    "& .MuiInputBase-root:not(.Mui-focused) fieldset": {
      border: `1px solid ${defaultStyle.textFieldGreyBorder}`,
    },
  };
  const styleGreyBorder = {
    "& .MuiInputBase-root.Mui-focused fieldset": {
      border: `1px solid ${defaultStyle.defaultColor}`,
    },
    "& .MuiInputBase-root:not(.Mui-focused) fieldset": {
      border: `1px solid ${defaultStyle.textFieldGreyBorder}`,
    },
  };
  const defaultStylesBlackBorder = IsBlackBorder
    ? styleBlackBorder
    : styleGreyBorder;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          color="primary"
          {...field}
          type={type}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="current-password"
          error={!!error}
          {...register(name, {
            onBlur: (e) => {
              if (onDataChange) {
                onDataChange(e);
              }
            },
          })}
          helperText={error?.message}
          label={<Typography variant="subtitle3">{label}</Typography>}
          sx={{
            ...defaultStylesBlackBorder,
          }}
          InputProps={{
            sx: {
              ...sx,

              backgroundColor: defaultStyle.whiteBackground,
              color: defaultStyle.defaultColor,
              fontSize: "14px",
              padding: "4px 14px 4px 0",
              height: "2.5rem",
            },
          }}
          {...other}
        />
      )}
    />
  );
}
