import React from 'react'
import { Typography, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { publicPaths } from '../../../routes/path';
import { defaultStyle } from "../../../config/Config";


  const List3 = [
    "Can I learn programming?",
    "How difficult is it to learn to code?",
    "How quickly would I be able to learn to code?",
    "Can I build my own software?",
    "Which programming language is the best? and many more..",
  ];

 const LearnCodeContent = () => { 
    const color = defaultStyle.secondaryColor;

    const navigate = useNavigate();
  
  const handleClick = (path) => {
      navigate(path)
    };

  return(
 <>
            <Typography variant="title">Learn to Code</Typography>
            <Typography color="grey">
              Learn to code in this era where generation is fast-paced moving
              towards learning <br />
              in a quick fashion.
              <br />
              Let me share you one interesting story of my son.
              <br />
              My son wanted to learn coding.
              <br />
              One day he comes to me and asks a dozen of questions.
              <br />
              Father
            </Typography>
            {List3.map((item) => (
              <ListItem
                key={item}
                disablePadding
                sx={{ display: "list-item", color: "grey", padding: 1 }}
              >
                {item}
              </ListItem>
            ))}
            <Typography
              sx={{ cursor: "pointer" }}
              variant="inherit"
              color={color}
              onClick={()=>handleClick(publicPaths.learnCode)}
            >
              Read More...
            </Typography>
          </>
  )}

  export default LearnCodeContent
