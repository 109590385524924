import React, {useEffect} from 'react';
import { Typography, Box, ListItem, Link } from "@mui/material";
import { defaultStyle } from '../../config/Config'
import LearnCodeImg from '../../assets/learn.059ee9ee.jpg'
import CodeingLang from '../../assets/coding-languagesImg1.png'
import PLATFORMSImg from '../../assets/PLATFORMSImg2.jpg'

const textPadding = {padding:"15px 0px"}
const linkStyle = {color:"red", fontWeight: '700'}

const LearnCode = () => {
  
  useEffect(() => {
  window.scrollTo(0, 0);
  }, [])

  
return(
<div style={{ display: "flex", justifyContent: "center" }}>
    <Box mb={3} width='70%'>
    <Typography  textAlign="center" color={defaultStyle.textGrey} variant="h4" sx={textPadding}>Learn to Code</Typography>
    <Box textAlign="center" >
      <img src={LearnCodeImg} alt="Clean Code" style={{ width: "70%" }}/>
    </Box>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Learn to code in this era where generation is fast-paced moving towards learning in a quick fashion.</Typography>
    <Typography variant="inherit" color="textSecondary" sx={{ padding: "15px 0px" }}>
      Let me share you one interesting story of my son. My son wanted to learn coding.
      One day he comes to me and asks a dozen of questions.
    </Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Father</Typography>
    <ListItem disablePadding sx={{ display: "list-item", color: "grey", fontWeight:"700"}}>
      I can get things done, does it really matter to think about a clean code?
    </ListItem>
    <ListItem disablePadding sx={{ display: "list-item", color: "grey" , fontWeight:"700"}}>
      How difficult is it to learn to code?
    </ListItem>
    <ListItem disablePadding sx={{ display: "list-item", color: "grey" , fontWeight:"700"}}>
      How quickly would I be able to learn to code?
    </ListItem>
    <ListItem disablePadding sx={{ display: "list-item", color: "grey" , fontWeight:"700"}}>
      Can I build my own software?
    </ListItem>
    <ListItem disablePadding sx={{ display: "list-item", color: "grey", fontWeight:"700"}}>
      Which programming language is the best?
    </ListItem>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>and many more..</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>He was eager to start immediately. Looking at his interest I decided to give him one of the best books on <Link sx={linkStyle} href="#">data structures</Link> and <Link sx={linkStyle} href="#">algorithms.</Link></Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>After spending a few days with that book, he comes back to me, saying, I don’t think you have given me the right material. I was rather looking for something which I could see happening, for example a <Link sx={linkStyle} href="#">mobile application</Link> or a <Link sx={linkStyle} href="#">program</Link> running on a <Link sx={linkStyle} href="#">computer.</Link></Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Sorry to say that the stuff you have provided me is really boring and I can’t keep it up with this.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I had no answer or anything to argue with my son. I took some privilege of time from him and told that I will try to find out the answers. Allow, me some time.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Now, I’d to do some work as I was having a list of enough questions to work upon.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I spent couple of days digging down in search for answers in practical manner.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>After my research trial and error I was able to compile the set of answers I was expected to share.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Luckily, I was having few students of mine who were really passionate about technology. I shared this compiled list with my students and my son to get the final approval</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Luckily, it worked out for them. And, they were able to solve real world problems through <Link sx={linkStyle} href="#">coding.</Link></Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Let me also share these answers with you. This list might help you in making the right decisions for your coding career. Here is the revealed list :</Typography>
    <Typography variant="h3" sx={textPadding}>Which programming language is the best for coding?</Typography>
    <Box>
      <img src={CodeingLang} alt="Clean Code" style={{ width: "70%" }}/>
    </Box>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Learn to code, you may wonder seeing the exhausted list of <Link sx={linkStyle} href="#">programming languages</Link> available these days. Now, to choose one seems like searching for your lost gem in the collection of thousands.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>And, this is most critical part towards the journey of coding. To start, first and foremost thing is to decide which programming language to work upon or to choose the one for your career.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>If you have already compiled this list and figured out the one for yourself than I would say you have already won half the battle and you can skip this part.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>If not, you can refer to the simple approach I tried myself in making this decision.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>1. Identify the Platform</Typography>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>2. Identify the programming language</Typography>
    <Typography variant="h3" sx={textPadding}>1. Identifying the Platform of your Interest
    </Typography>
    <Box>
      <img src={PLATFORMSImg} alt="Clean Code" style={{ width: "70%" }}/>
    </Box>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}><Link sx={linkStyle} href="#">Computer Platform</Link> is basically an environment where your piece of coding or software will be executed.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Now, rather than directly delving into the list of programming languages and get exhausted with it. Let’s try to reduce the number of options by looking at the picture at higher level.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Start from computer platforms and drill down towards the programming languages which are currently used to support that platform. Different platforms, such as : Windows, Linux, iPhone, MAC, Android, Web, Artificial Intelligence, Hardware, Database etc.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Here, we are trying to reduce the number of options, so that it becomes easy for you to identify your dream language.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>We are not trying to find out the programming language rather it is to unlock your passion for success.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>When you scan the platform with its layers. for example <Link sx={linkStyle} href="#">Android platform</Link> with its <Link sx={linkStyle} href="#">layers</Link> such as : Hardware Layer, Native Layer, Application Layer, etc..</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Let’s suppose Hardware Layer makes more sense to you or it is as per your heightened interest. If you drill down you can find the supporting programming languages such as : <Link sx={linkStyle} href="#">C</Link> , <Link sx={linkStyle} href="#">C++</Link> could be the options to go with. Now, it becomes fairly easy for you to decide which language to start with. It has long term impact in your career.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I am not forcing you to stick to any one programming language or computer platform. I am rather suggesting you to do this research before you start to code. And,</Typography>
    <Box sx={{backgroundColor: "lightgrey", padding:"10px" }}>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>“when you start, you learn it fast, as you go with your passion or interest”</Typography>
    <Typography textAlign='right' color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>-Harsh Prashar </Typography>
    </Box>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>Interest is the key to success.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>It is worth spending time on deciding the platform and a programming language.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Explore these different platforms and try to figure out the ones which interests you most.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Your journey towards “learn to code” will begin with this.</Typography>
    <Typography variant="h3" sx={textPadding}>2. Identifying the coding language of your Interest</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Once you identify the platform, look for all the different programming languages which works in support for your chosen platform.</Typography>
    <Typography color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Again, explore these languages. You may have to go through the basic information or idea behind the creation of each programming language being built. Go through the history and future support of the language.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>Why is it Important to choose coding/programming language of your Interest?</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>It may not be a good idea to just pick up any programming language and start coding. By going through hit and trial method, you may end up spending more time in just understanding the code syntax rather than the actual designing aspects.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Here, we are trying to reduce the number of trials you will go through. Its okay if you are not able to come up with one language or platform at this time, you can still go ahead and try out different platforms and supporting languages for few days and later decide for your favourite ones. But, you definitely have to work to reduce the number of options you currently have.</Typography>
    <Typography variant="h3" sx={textPadding}>How to Learn to Code?</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Seeing the title of the page, you may have wondered to start immediately with this. But, you may also agree to the importance of points mentioned above towards this journey.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Here I am going to reveal the secret behind coding.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>How to make it interesting?</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I think this was the first and most important of all.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I would want to bring your attention to the point, if you are serious about learning to code, you must learn to use <Link sx={linkStyle} href="#">data structures</Link> and <Link sx={linkStyle} href="#">algorithms</Link></Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Some of you may argue that we came here to learn to code, not data structure. Don’t worry I will come back to that but learning to code without data structure is like a field engineer without his kit of tools.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Data structure is definitely not the code, but it will well equipped you with the tools you need while coding.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Let me take you back to the story of my son. I did not try to convince him to learn the data-structure and algorithms, rather I did something different.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I tried to give him a set of real world problems and asked for a solution on a piece of paper, not a computer program.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Here is the first problem I gave :</Typography>
    <Typography variant="h3" sx={textPadding}>PROBLEM 1 : Prepare one cup of tea at a time and make sure the amount of sugar added will be as per the customer’s choice.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Before you jump into solution below, try to solve it by yourself using pen and paper.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>SOLUTION 1 : The solution would comprise of few steps such as :</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>STEP 1 : To prepare a cup of tea, you should have these inputs available : Water, Milk, Tea Leaves, Sugar, Pan, Cup and a Heater.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>STEP 2 : Provision to get input as sugar from the customer.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>STEP 3 : Tea Processor or a pot, which will fetch the right amount of material and sugar required to prepare for a cup of tea.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>STEP 4 : Delivery module, to deliver once tea is ready to be served.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>He shared these steps to me. Now, I asked him to create a computer program which will prepare that one cup for me, what will be required. His reply : hmm, … I don’t know. Now, its my turn to play. The answer is data structure, to have all the information stored somewhere, it is required to have a data structure which is going to be backbone of your coding.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>For example quantity of water required for one cup of tea has to be fetched and stored using data-structure.</Typography>
  <Typography variant="h3" sx={textPadding}>PROBLEM 2 : Searching for friend’s friend on facebook or instagram.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>Hint : Before diving and thinking about facebook or instagram, you can try this out in your friend circle. Lets suppose you remembered your school friend but you don’t have any contact information to reach him but you are certain that he may be in your friends friend list, how will you find that person out if you don’t have facebook or social media account.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Solution 2 :</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit">1. Since he/she is a school friend, you will locate your friends of that school.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit">2. Next, if he/she was studying in the same batch, you will have a filtered list.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit">3. If you know the location of the person. You keep reducing the number of people you need to contact.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>You would probably try to reduce the number of options as above and finally, you will reach out the shortlisted people and ask them about your friend.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>This is how you find the details of your friend.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Now, you already know the algorithm, you can simply apply it here. Creating algorithm but you are not aware which data structure. Now, you may understand the importance of data-structure in <Link sx={linkStyle} href="#">Computer Science</Link></Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Here, we will be using Graph, what is graph and why? If you are interested in knowing the complete solution, write in the comment box below.</Typography>
  <Typography variant="h3" sx={textPadding}>PROBLEM 3 : I have a library of incredible books, where people often visits and read books. These books have been arranged properly as per their category. Now, since there is no one to look after these readers. They don’t put the books back at the places where they are suppose to be kept.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>This keeps annoying me. I am looking for an automated solution to this problem, please tell me what should I do?</Typography>
  <Typography variant="h3" sx={textPadding}>PROBLEM 4 : I am a reader and I read many books, but there is a problem. I easily forget the important aspects which I highlight in the book. Though I mark those lines with some background, but still I never felt like opening that book again, just to read the markers.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I am looking for a solution which can generate a copy of those marked points with the title of the chapter and if I want to navigate to that page in the book, I should be able to do that.</Typography>
  <Typography variant="h3" sx={textPadding}>PROBLEM 5 : These days there are too many advertisements coming on my favorite TV Programs. As soon as advertisements appear on the program I immediately switch between the programs.</Typography>
  <Typography variant="h3" sx={textPadding}>I need a solution which can automatically switch to a different program among my selected list. It should be triggered only when there is an advertisement running, so that I don’t have to manually switch the program.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>I understand, if you are running your programs in TV Channels and earn through advertisements. You are going to hate this being called a problem. I am sorry for that. There are many such real world problems I created for my students. Putting them here might distract the course of our action. If you are interested in knowing the complete list of problems with its solution, write in the comment box below or contact us <Link sx={linkStyle} href="#">here.</Link></Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>With this tutorial of ‘learn to code’ I would want to refer you to the real world problems around us. You yourself might observe them, the problems can be really big, hard or small doesn’t matter. Keep adding these problems in a note book or somewhere in your records.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Try to look for solution as and when you get some time. You need to breakdown the problem statement into smaller parts, so that you can identify the portions which can be solved using a computer program.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Before you directly jump into your computer screen to type your code. I would suggest you create the algorithm on a piece of paper, so later you can execute the same using a computer program.</Typography>
  <Typography variant="h3" sx={textPadding}>Conclusion</Typography>
  <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>All these incredible software programs today, are just because of the great problems. You never know when you get into this habit of solving problems, one day you might also be the one offering great solution to the world.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I owe my regards to you, best of luck with your coding career. If you are looking for a mentor who can help you with your coding career, please fill up this form and send it.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>If you thing whatever I shared makes sense to you, hit the like button and subscribe here for more contents on software programming practices.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>There is another important article on Clean Code, you must read that for your successful coding career.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Refer to this link <Link sx={linkStyle} href="#">‘Write Clean Code in One Statement’</Link> for the same.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>If you haven’t tried the ‘Code Generator’ as yet. Please refer to this link <Link sx={linkStyle} href="#">here</Link> for free download of the application.</Typography>
  <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>This tool can help you generate professional source code in few clicks.</Typography>

  </Box>
  </div>)
};

export default LearnCode;