import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, Grid } from "@mui/material";
import SubmitButton from "../button/SubmitButton";
import { useNavigate } from "react-router-dom";
import { publicPaths } from "../../routes/path";

PricesCard.propTypes = {
  priceData: PropTypes.object.isRequired,
};

function PricesCard({ priceData }) {
  const [boxColor, setBoxColor] = useState(false);
  const navigate = useNavigate();

  const getSymbol = (value) => (value ? "✔" : "✘");

  const snakeCaseToReadable = (text) =>
    text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 900) {
        setBoxColor(false);
      } else {
        setBoxColor(true);
      }
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        padding: "4% 20%",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {Object.keys(priceData).map((key) => {
          if ((key === "Features" && !boxColor) || key !== "Features") {
            return (
              <Grid item xs={12} md={6} key={key}>
                <Paper
                  sx={{
                    transition:
                      "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
                    "&:hover": {
                      backgroundColor: "rgb(175 174 174 / 40%)",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <Box sx={{ minHeight: "100px" }}>
                    <Typography
                      variant="h6"
                      textAlign="center"
                      sx={{ paddingBottom: "15px", paddingTop: "15px" }}
                    >
                      {snakeCaseToReadable(key)}
                    </Typography>
                    {key === "Business" && (
                      <Typography
                        variant="h6"
                        color="white"
                        backgroundColor="red"
                        textAlign="center"
                        marginBottom="30px"
                      >
                        Our most valuable package!
                      </Typography>
                    )}
                  </Box>
                  {Object.entries(priceData[key]).map(
                    ([featureKey, featureValue], index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "2px 0",
                        }}
                      >
                        <Box
                          textAlign="center"
                          sx={{
                            backgroundColor: "rgb(175 174 174 / 26%)",
                            padding: "10px 0",
                            width: "100%",
                          }}
                        >
                          {boxColor && (
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              gutterBottom
                            >
                              {snakeCaseToReadable(featureKey)}
                            </Typography>
                          )}
                          <Typography>
                            {typeof featureValue === "boolean"
                              ? getSymbol(featureValue)
                              : typeof featureValue === "string"
                              ? featureValue
                              : null}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}
                  {key !== "Features" && (
                    <Box sx={{ margin: "auto", textAlign: "center" }}>
                      <SubmitButton
                        handleClick={() => {
                          key === "Single_User"
                            ? navigate(publicPaths.tryFree)
                            : navigate(publicPaths.paymentCard);
                        }}
                        Content={key === "Single_User" ? "TRY NOW" : "CHOOSE"}
                        sx={{ width: "35%", height: "40px", margin: "10px" }}
                      />
                    </Box>
                  )}
                </Paper>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </Box>
  );
}

export default PricesCard;
