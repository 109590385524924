import React from 'react'
import { Typography, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { publicPaths } from '../../../routes/path';
import { defaultStyle } from "../../../config/Config";


  const List1 = [
    "Do I need to learn about it?",
    "Is this going to bring more money into my pocket?",
    "Is this in a fashion to talk about clean code?   Ultimately, what matters is a thing which works.",
    " I have already completed coding very complex applications but I never realized something called CLEAN.",
    "  I have been through a lot of pressure from my boss, are you really asking me to think about Clean Code?",
  ];

 const CleanCodeContent = () => { 
    const color = defaultStyle.secondaryColor;

    const navigate = useNavigate();
  
  const handleClick = (path) => {
      navigate(path)
    };

  return(
    <>
            <Typography variant="title">
              Write Clean Code in one Statement
            </Typography>
            <Typography variant="inherit">
              When someone talks about clean code programming, does these
              questions ever bother you :
            </Typography>
            <Typography color="grey">
              I can get things done, does it really matter to think about a
              clean code?
            </Typography>
            {List1.map((item) => (
              <ListItem
                key={item}
                disablePadding
                sx={{ display: "list-item", color: "grey", padding: 1 }}
              >
                {item}
              </ListItem>
            ))}
            <Typography
              sx={{ cursor: "pointer" }}
              variant="inherit"
              color={color}
              onClick={()=> handleClick(publicPaths.cleanCode)}
            >
              Read More...
            </Typography>
          </>
  )}

  export default CleanCodeContent
