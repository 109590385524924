import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import PaymentCard from "../../../assets/PayCard.webp"
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Box, Card, CardContent
} from "@mui/material";
import { publicPaths } from "../../../routes/path";


const schema = Yup.object().shape({
  cardNumber: Yup.number().typeError("That doesn't look like a Expiration Year")
    .positive("A Card Number can't start with a minus")
    .min(1000000000000000, "Card Number must have 16 digit")
    .max(10000000000000000, "Card Number must have 16 digit")
    .integer("A Card Number can't include a decimal point")
    .required('A Card Number is required'),
  cardHolder: Yup.string().required("Card Holder is required"),
  month: Yup.number().required("Month is required"),
  expirationYear: Yup.number().required('A Expiration Year is required'),
  cvv: Yup.number().typeError("That doesn't look like a CVV")
    .positive("A CVV can't start with a minus")
    .min(1000, "CVV must have 4 digit")
    .max(9999, "CVV must have 4 digit")
    .integer("A CVV can't include a decimal point")
    .required('A CVV is required'),
});

function CreditCardForm() {
  const { handleSubmit, register, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const onSubmit = () => {
    navigate(publicPaths.pricing)
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" minHeight="100vh">
      <Box sx={{ position: "relative", top: "120px" }}>
        <img src={PaymentCard} alt="Payment" />
      </Box>
      <Card elevation={3} sx={{ minWidth: 300, width: "60%", padding: "30px", paddingTop: "150px" }}>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Card Number"
                  {...register("cardNumber")}
                  error={!!formState.errors.cardNumber}
                  helperText={formState.errors.cardNumber?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Card Holder"
                  {...register("cardHolder")}
                  error={!!formState.errors.cardHolder}
                  helperText={formState.errors.cardHolder?.message}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    {...register("month")}
                    error={!!formState.errors.month}
                    label="Month"
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    {...register("expirationYear")}
                    error={!!formState.errors.expirationYear}
                    label="Expiration Year"
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2023}>2025</MenuItem>
                    <MenuItem value={2024}>2026</MenuItem>
                    <MenuItem value={2023}>2027</MenuItem>
                    <MenuItem value={2024}>2028</MenuItem>
                    <MenuItem value={2023}>2029</MenuItem>
                    <MenuItem value={2024}>2030</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CVV"
                  {...register("cvv")}
                  error={!!formState.errors.cvv}
                  helperText={formState.errors.cvv?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CreditCardForm;
