import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useFormContext, Controller } from "react-hook-form";
import { Switch, FormControlLabel } from "@mui/material";
import { defaultStyle } from "../../config/Config";

CustomSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onDataChange: PropTypes.func,
  sx: PropTypes.object,
};

export default function CustomSwitch({
  name,
  label,
  onDataChange,
  sx,
  ...other
}) {
  const { control } = useFormContext();
  const StyledSwitch = styled(Switch)({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: defaultStyle.primaryColor,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: defaultStyle.primaryColor,
    },
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <StyledSwitch
              {...field}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.target.checked);
                if (onDataChange) {
                  onDataChange(e);
                }
              }}
              sx={sx}
              {...other}
            />
          }
          label={label}
        />
      )}
    />
  );
}
