import { Link } from "@mui/material";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";

NavList.propTypes = {
  item: PropTypes.object,
};
function NavList({ item }) {
  return (
    <Link
      underline="none"
      variant="body2"
      sx={{
        padding: "1vw",
        "&:hover": {
          color: "#ff5745",
          boxShadow: 0,
        },
        color: "black",
      }}
      component={RouterLink}
      to={item.path}
    >
      {item.title}
    </Link>
  );
}

export default NavList;
