import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { defaultStyle } from "../../config/Config";
import { useTheme } from "@mui/material/styles";

CustomButton.propTypes = {
  handleClick: PropTypes.func,
  Content: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.any,
  error: PropTypes.bool,
  Icon: PropTypes.any,
  size: PropTypes.string,
};
export default function CustomButton({
  handleClick = () => {},
  Content,
  disabled,
  sx,
  error,
  Icon,
  size,
}) {
  const theme = useTheme();
  return (
    <Button
      onClick={() => handleClick()}
      variant="outlined"
      size={`${size}`}
      disabled={disabled}
      color={error && "error"}
      sx={{
        ...sx,

        color: defaultStyle.buttonTextColor,
        bgcolor: error
          ? defaultStyle.errorBgcolor
          : theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        // borderRadius: 2,
        ":hover": {
          bgcolor: theme.palette.info.main,
          borderColor: theme.palette.info.main,

          //   color:  defaultStyle.buttonColor,
        },
      }}
      startIcon={Icon}
    >
      {Content}
    </Button>
  );
}
