import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";
import { defaultStyle } from "../../config/Config";

const CustomTrimmer = styled(Slider)(() => ({
  height: 53,
  color: "#dbdbdb",
  borderRadius: 1,
  "& .MuiSlider-thumb": {
    height: 45,
    width: 9,
    borderRadius: 2,
    backgroundColor: defaultStyle.buttonColor,
    "&::before": {
      content: '""',
      position: "absolute",
      width: 1,
      height: 41,
      borderRadius: "50%",
      backgroundColor: "white",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    "&:hover, &:active": {
      boxShadow: "none",
    },
  },
}));

export default CustomTrimmer;
