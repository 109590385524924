import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./Navbar";

function MainLayout() {
  return (
    <Box>
      <Navbar />
      <Outlet />
      </Box>
  );
}

export default MainLayout;
