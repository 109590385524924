import React, {useEffect} from 'react';
import { Typography, Link, Box } from "@mui/material";
import { defaultStyle } from '../../config/Config'
import kotlinImg from '../../assets/kotlin_can_be_a_friend.25ccc53c.jpg'
import kotlin_snippetImg from '../../assets/kotlin_snippetImg.png'
import kotlin_snippet2 from '../../assets/kotlin_snippet2.png'
import kotlin_snippet3 from '../../assets/kotlin_snippet3.png'


const textPadding = {padding:"15px 0px"}
const linkStyle = {color:"red", fontWeight: '700'}

const KotlinInfo = () => {
  
  useEffect(() => {
  window.scrollTo(0, 0);
  }, [])

  return(
<div style={{ display: "flex", justifyContent: "center" }}>
    <Box mb={3} width='70%' alignItem='center'>
    <Typography  textAlign="center" color={defaultStyle.textGrey} variant="h4" sx={textPadding}>Kotlin Coroutine can be a friend</Typography>
    <Box textAlign="center" >
      <img src={kotlinImg} alt="Clean Code" style={{ width: "70%" }}/>
    </Box>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>When we first glance at something new, we tend to loose attention and most of the times we ignore.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>If that same new object is seen by a child, he/she gets curious and applies even more attention to it. If you have ever accompanied a kid, you might have observed the set of questions, which can boggle your mind. That clearly depicts the urge of learning, which unfortunately has been lost in majority.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Same thing happens even when we call ourselves experienced or professionals. Unfortunately, we tend to ignore the stuff which is not recorded in our brain before.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit">I did the same thing with Kotlin Coroutine. I saw and ignored, so quickly that it never existed.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Later, when I started doing Kotlin programming, I had to perform network operations with my server.
    I tried to look for the ways to perform <Link sx={linkStyle} href="#">Asynchronous</Link> tasks. I looked at different options and finally returned to Kotlin Coroutine.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Honestly, when I gave that required attention, I find it to be a friend. The advantages of Coroutine were really impressive.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>With this post I will try to explain Coroutine, so that you can quickly integrate this into your live project.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Let’s begin…
    </Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I will be using some real world scenarios to first understand the use case.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>EXAMPLE 1 : You went to a hotel and receptionist has asked for your name. Now, that can be treated as a prompt action and receptionist may not need to wait for you to figure out your name.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>It is a  <Link sx={linkStyle} href="#">‘synchronous‘</Link> call. This can be achieved using simple method where coroutine or ‘asynchronized’ task may not be required.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>EXAMPLE 2 : You visit a restaurant and order for a cup of hot tea with sandwich.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Now, if we look at pantry. There can be n-number of orders to be processed by chefs. If we think of chef’s as threads running to execute or prepare for the incoming orders. It should be processed based on the priority set for different orders.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>And, in a restaurant there can be limited number of chefs depending upon the business or the customer base. Similarly, there can be limited number of threads which can be consumed by an application at any particular time.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Lets come back to your order, “a hot cup of tea with sandwich”, which means before delivering your order, chef who is preparing for your tea needs to wait for sandwich to get prepared or vice-versa, there will be a callback before final delivery, this process can be treated as asynchronous.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>This situation explains us the concept of <Link sx={linkStyle} href="#">multithreading.</Link></Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>To achieve all of this using Kotlin, Coroutine is going to be a friend of yours. You already know the scenario. Let’s understand how it works?</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>If you have already heard about <Link sx={linkStyle} href="#">Threads, Tasks, Executors , Callbacks</Link> , and more…, simply forget it.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Yes, you assumed it correctly, all of this is not required, because, Coroutine is going to do this for you.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Lets understand what Coroutine can do :</Typography>
    <Box sx={{backgroundColor: "lightgrey"}}>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>1. The coolest thing, GETTING ASYNCHRONOUS TASKS DONE AS IF YOU ARE DOING IT SEQUENTIALLY.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>   You just need to put suspend keyword before your method and it becomes asynchronous.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>2. Coroutine is Lightweight.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>3. Coroutine provides more control over your long-running operations.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>4. This may be another surprise to you, as it was for me. Many Coroutines can run in a single thread.</Typography>
    </Box>




    <Typography  color={defaultStyle.textGrey} variant="h6" sx={textPadding}>Lets understand Coroutine with the help of a use case :</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>We will use Coroutine in our program to validate input credentials from server. To identify if the user has the access for any particular feature.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Just go through the source code snippet attached below :</Typography>
    <Box>
      <img src={kotlin_snippetImg} alt="Clean Code" style={{ width: "70%" }}/>
    </Box>
    
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>In the example above, fetchCredentials() is the suspend method which contains the source code to perform long running network operation and returns JSON String in the response.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>I am using this snippet to help you understand the use case, adding source code to make actual server calls is out of scope for this tutorial and may be distracting.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit">Let’s understand what each keyword means and what to expect from them :</Typography>
    <Typography variant="h3" sx={textPadding}>suspend Keyword</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>suspend keyword indicates that the method can suspend the execution of the given operation.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit">Create simple method and put suspend keyword before the name.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Doing so, makes your function appropriate for long-running operations or to perform asynchronous tasks.</Typography>
    <Typography variant="h3" sx={textPadding}>launch Keyword</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Coroutine methods needs to be called within the scope, Scope may sound another strange keyword, but it has very nice use case.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Scope is used to handle your long-running operations depending upon your requirements. You might need your operations to run within the boundaries of the life-cycle of the activity or the application.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Lifecycle of a coroutine method :</Typography>
    <Typography  variant="h3" sx={textPadding}>1. Keep your long running operation active till your application is alive</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>This can be achieved using GlobalScope.launch keyword.</Typography>
    <Box>
      <img src={kotlin_snippet2} alt="Clean Code" style={{ width: "70%" }}/>
    </Box>
    <Typography  variant="h3" sx={textPadding}>2. Keep your long running operations active till your current activity is alive .</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>This can be achieved using launch keyword.</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={textPadding}>Below code snippet will keep the operation running till current activity is alive.</Typography>
    <Box>
      <img src={kotlin_snippet3} alt="Clean Code" style={{ width: "70%" }}/>
    </Box>
    <Typography variant="h3">Conclusion</Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={{ padding: "15px 0px" }}>
      The simplicity coroutine has offered helped me to perform long-running operations in an easy way.
    </Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={{ padding: "15px 0px" }}>
      To get you started quickly, I have kept this tutorial short without adding too many details.
    </Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={{ padding: "15px 0px" }}>
      If you need to understand it in depth, please check this link <Link sx={linkStyle} href="#">here</Link>.
    </Typography>
    <Typography  color={defaultStyle.textGrey} variant="h6" sx={{ padding: "15px 0px" }}>
      If you haven’t tried the ‘Code Generator’ yet, or if you feel lazy while writing the source code as I do, this tool <Link sx={linkStyle} href="#">here</Link> might be of use to you.
    </Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={{ padding: "15px 0px" }}>
      Please share your comments; I would love to hear from you!
    </Typography>
    <Typography  color={defaultStyle.textGrey} variant="inherit" sx={{ padding: "15px 0px" }}>
      Don’t miss <b style={{color:"red"}}>subscribe</b>; I will be sharing more content on software programming practices.
    </Typography>
  </Box>
  </div>
)};

export default KotlinInfo;
