import { Grid, List, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SubmitButton, NavList } from "../../components";
import logo from "../../assets/synstaioLogo.svg";
import { Navconfig } from "./Navconfig";
import { publicPaths } from "../../routes/path";

function DesktopNavbar() {
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        height: "110px",
        padding: "9px 45px",
      }}
    >
      <Grid item xs={2}>
        <Box>
          <img
            src={logo}
            alt="img"
            style={{
              height: "60px",
              width: "165px",
              color: "blue",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={9}>
            <List>
              <Grid container alignItems="center" justifyContent="center">
                {Navconfig.map((item, Index) => (
                  <NavList key={Index} item={item} />
                ))}
              </Grid>
            </List>
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={"flex-end"}
          >
            <SubmitButton
              Content="SIGN UP"
              sx={{ width: "90px", height: "40px", marginRight: "10px" }}
              handleClick={() => navigate(`${publicPaths.register}`)}
            />
            <SubmitButton
              size="medium"
              sx={{ width: "90px", height: "40px" }}
              Content="SIGN IN"
              handleClick={() => navigate(`${publicPaths.login}`)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DesktopNavbar;
