import React, {useEffect} from 'react'
import { Typography, Box, ListItem } from "@mui/material";
import { defaultStyle } from '../../config/Config'
import CleanCode from '../../assets/clean-code-water.d6a8756e.jpg'
import codeImg1 from '../../assets/codeImg1.png'
import codeImg2 from '../../assets/codeImg2.png'
import LifeCycleImg from '../../assets/softwareDevelopementLifeCycleImg.jpg'

const textPadding = {padding:"15px 0px"}

const CleanCodeInfo = () => { 
  
  useEffect(() => {
  window.scrollTo(0, 0);
  }, [])

  return(
<div style={{ display: "flex", justifyContent: "center" }}>
    <Box mb={3} width='70%' alignItem='center'>
        <Typography textAlign="center" color={defaultStyle.textGrey} variant="h4" sx={textPadding}>Write Clean Code in one Statement</Typography>
          <Box textAlign="center" >
            <img src={CleanCode} alt="Clean Code" style={{ width: "70%" }}/>
          </Box>
        <Typography variant="h4" color={defaultStyle.textGrey} sx={textPadding}>
          When someone talks about clean code programming, does these questions ever bother you
        </Typography>
              <ListItem disablePadding sx={{ display: "list-item", color: "grey"}}>
              I can get things done, does it really matter to think about a clean code?
              </ListItem>
              <ListItem disablePadding sx={{ display: "list-item", color: "grey"}}>
              Do I need to learn about it?
              </ListItem>
              <ListItem disablePadding sx={{ display: "list-item", color: "grey"}}>
               Is this going to bring more money into my pocket?
              </ListItem>
              <ListItem disablePadding sx={{ display: "list-item", color: "grey"}}>
               Is this in a fashion to talk about clean code? <br/> Ultimately, what matters is a thing which works.
              </ListItem>
              <ListItem disablePadding sx={{ display: "list-item", color: "grey"}}>
               I have already completed coding very complex applications but <br/>
               I never realized something called CLEAN.
              </ListItem>
              <ListItem disablePadding sx={{ display: "list-item", color: "grey"}}>
               I have been through a lot of pressure from my boss,. <br/>are you really asking me to think about Clean Code?
              </ListItem>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>If you have ever come across any of these questions, you are at the right place. I am going to explain each and everything about clean code.</Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Don’t worry, I won’t share a dozen of links and neither a book of 200 pages. This will be very brief and simple. Only that much, which is required for the success of a software system.</Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}><b>SUCCESS OF A</b> <b style={{color:defaultStyle.textRed}}>SOFTWARE SYSTEM</b>  yes I mentioned it. A clean code can lead to a successful software system.</Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>In fact, it will be just <b>ONE STATEMENT</b> which is going to do wonders. I will come back to that, but let’s understand what it is and why its required?.</Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>“CLEAN” represents completeness. If we plug “CODE” with it, it simply becomes a snippet which in all aspects brings clarity.</Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>Lets look at the aspects of a Clean Code programming:</Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>1. Naming Conventions.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>2. Logs.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>3. Code Formatting.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>4. Adding small modules.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>5. GIGO (Garbage-in-garbage-out).
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>6. Code Maintenance.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>7. Conclusion
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>I understand you might have heard of these terms many times before. Don’t worry about any of these points right now.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey}>I will simply highlight these aspects here.
        </Typography>
        <Typography variant="h3" sx={textPadding}>Clean Code Naming Conventions
        </Typography>
        <Typography variant="inherit" sx={textPadding}>Naming Conventions sound really boring to me, but if I call a “BOOK”, piece of papers or a “TABLE” to wood or a “GLASS” to metal.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>How difficult would it be to understand what the later means. Same is the case with the software code. Writing a constant name, I prefer
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>SERVER_DEFAULT_URL rather than ABC or XYZ.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>This applies to variables, method names, class names, etc..
          Use proper names, if they are not exact but, at least close in the meaning to what they are suppose to perform.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Example :
        </Typography>
        <Typography variant="h4" color={defaultStyle.textGrey} sx={textPadding}>Write a small function/method which performs data validation for the JSON type input.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>I suggest you to try this out yourself and compare with the below code snippet.
        Just mention the flow, rather than the complete code.
        </Typography>
          <Box item xs={12} md={4}>
            <img src={codeImg1} alt="Clean Code" style={{ width: "90%" }}/>
          </Box>
          <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>You might have observed <b>Error Codes, Log types, Log Modules.</b> These are the generic utilities which shall be utilized throughout the entire application.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>If the above code snippet has to be used as a library by some one else, it should be straight forward and easy to use. As with <b>jsonString</b> input name, one can identify that it is not going to work with any other string types.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Here, this <b>input</b> name ‘jsonString’ is forcing user to check for the input being sent. It will be a good practice to add some documentation about your code, but sometimes that can also be ignored.
        </Typography>
        <Typography variant="h3" sx={textPadding}>Clean Code LOGS
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}> I have also added enough Logs while writing the above source file. Logs should be treated as a story telling book, rather than fully technical and alien to the tester or anyone else. I have seen people writing horrendous log files which are too technical and definitely of no use to anyone other than the self. And, sometimes not even self.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>The advantages of writing this story book are as follows :
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>– Tester who is accompanying you in testing your piece of code can get the benefit of analyzing and performing <b style={{color: defaultStyle.textRed}}>white box testing.</b>
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>– It will be easy to find out bugs in the code by just going through the logs rather than scrabbling and digging into the source code.
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>– Any body in a team can contribute to bug fixes with the help of human readable log files.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Things you need to take care while writing your log files:
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>1. Keep your Logs as a separate utility:
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>One can easily enable/disable all or any particular log category. By category I mean following types : Log.warning(), Log.debug(), etc…
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Also because, sometimes you may have to disable certain or all type of logs from a final product.
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>2. Logs should be written as you start with the source code:
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>I have seen developers writing logs at later stage when they start finding bugs or it is required to dump some data.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>At later stage you may not be fully aware about everything or you may forget things which are important to mention in the logs.
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>3. Only one utility for Logs:
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>In the entire software system, ideally, there should be only one utility for logs being utilized by all team member of that software product, rather than everyone doing it in their own way.
        Applying these small tactics can really help you write ‘clean code’.
        </Typography>
        
        <Typography variant="h3" sx={textPadding}>Clean Code Formatting
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>Every programming language have its own way of formatting software code. I will try to explain it using some examples.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Please forgive me if you find these examples vague. Somehow, people think the coding has to be very different and cumbersome, so that it becomes impossible to understand what this code means or what it does.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Rather, I think it should be simple enough for anyone to understand. You may be having valid reasons to debate for this thought. Please skip, if so.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>When you hold a book with fantastic nice title and of your interest, if the text is not aligned or the paragraphs are not divided into proper groups. It would be really hard to read that book, despite of your interest, you may end up keeping that book in the corner.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Similarly, it is there with the software code. You may have experienced this in your coding career.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>If you are assigned to work on the software code written by someone else, most likely your first intention would be to avoid being part of that mess. Rather, you prefer to work on a fresh feature or piece of code. And, same is the reason you are here, reading this tutorial.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>You may have heard of this many times before, keep your code modular or divide your method into smaller pieces. But, I could see the compiler doesn’t have any issue with it, so why do you?
        </Typography>
        <Typography variant="h3" sx={textPadding}>Add Small Modules to Clean Code
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>Add You may have heard of this many times before, keep your code modular or divide your method into smaller pieces.
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey}>But, I could see the <b style={{color: defaultStyle.textRed}}>compiler</b> doesn’t have any issue with it, so why do you?
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Add Let me explain why?
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>You have a shelf cluttered with books of different categories. Write a program to group these books as per the category (sa. Fiction, Adventure, Business, etc.) and print that categorized list into one file.
        </Typography>  
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Before you try to solve this problem with your <b style={{color: defaultStyle.textRed}}>computer program</b> or using <b style={{color: defaultStyle.textRed}}>pseudocode</b>. I would suggest you to try it out manually before reading it further.
        </Typography>  
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Doing it manually, you will hold each book at a time and look for the category. Once you identify the category you will place it at appropriate location. And, you keep on doing this till the last book has approached.
        </Typography> 
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Finally, you will have a nice clean shelf shinning with books.
        </Typography> 
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Try to arrange your books and find out different ways of doing it, so you can improve on your algorithm as well.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>I am dumping the sample source code for this. I will only add the portion of code.
        </Typography>
        <Typography variant="h3" color={defaultStyle.textGrey} sx={textPadding}>Example Code for Small Modules to Clean Code
        </Typography>
          <Box item xs={12} md={4}>
            <img src={codeImg2} alt="Clean Code" style={{ width: "100%" }}/>
          </Box>
          <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>In the above code snippet, I have tried to divide it into smaller portions of code. You might observe these methods :
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>–categorizeBooks(List booksInput);
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>– validateInput(booksInput);
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>– fetchCategory(book);
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>– getResultIndex(categorizedList, category);
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}> – Log.information, Log.warning, ..
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>– FileUtils.writeIntoFile(books);
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>– FileUtils.writeIntoFile(books);
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>Where Log and FileUtils shall be part of generic utilities and can be utilized through out the whole application.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Entire portion of the <b style={{color: defaultStyle.textRed}}>source code</b> could also be executed within one method but dividing it into smaller portions can be helpful for testing, bug fixing, and maintenance. You also get the benefit of reduced, compact source code by reusing the existing utility methods or libraries.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey}>
        Dividing your biger files into smaller chunks can lead to ‘clean code’
        </Typography>
        <Typography variant="h3" sx={textPadding}>
        GIGO (Garbage-in-garbage-out)
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>
        This is the most interesting one. You might have heard of situations where the piece of software code which was working till yesterday has stopped working now. This is a funny situation to be in.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        The occurrence of such problems are very frequent. There could be different reasons for the occurrence, but most likely it is something to do with data.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Let me share the problem I recently came across. My application at client side was configured to pull maximum 3 records at a time from the server. The data pull was happening using the greatest timestamp stored at client.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        For some unknown reason, the utility which was used to store the current timestamp with each record did not work. It caused to create duplicate entries of timestamp for 7 new records.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        It looked something like this:
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey}>
        Data 1 : Timestamp 1000
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey}>
        Data 2 : Timestamp 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey}>
        Data 3 : Timestamp 1001</Typography>
        <Typography variant="inherit" color={defaultStyle.textRed} sx={{paddingTop:"15px"}}>
        Data 4 : Timestamp 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.SecondryColor}>
        Data 5 : Timestamp 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>
        Data 6 : Timestamp 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>
        Data 7 : Timestamp 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textRed}>
        Data 8 : Timestamp 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={{paddingTop:"15px"}}>
        Data 9 : Timestamp 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} >
        Data 10 : Timestamp 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        EXECUTION 1 : Fetch Data  Timestamp (NULL or 0) Since, my code could only pull 3 records at a time. So the result : Data 1, Data 2, Data 3 Greatest Timestamp : 1001
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        EXECUTION 2 : Fetch Data  Timestamp 1001 So the result : Data 9, Data 10 Greatest Timestamp : 1003
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        You will observe that the data for records from 4, 5, 6, 7 and 8 as marked with orange color, above, couldn’t be retrieved .
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        It is because of the garbage entered into the database. By the time you realize the bug in the system, your database has already grown with thousands of new records. Now, these type of problems are really hard to identify.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        In my situation customer wasn’t all that patient. Luckily, I observed the duplicate entries and fixed the problem. Here, if you try to solve the problem with data pull API, you may never be able to resolve the issue and it would keep on bothering you at some random intervals.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Data is a crucial component of a software system and passing garbage to the system will only result in the garbage. It is important to take care of your data towards ‘clean code’.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Code Maintenance You might agree to the fact that majority of the successful software systems have surrounded us since substantially long time, such as iPhone released at June 29, 2007 and even some of them are around since generations such as Windows, released at 20 November 1985.
        </Typography>
        <Typography variant="h3" sx={textPadding}>
        Code Maintenance
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>
        You might agree to the fact that majority of the successful software systems have surrounded us since substantially long time, such as iPhone released at June 29, 2007 and even some of them are around since generations such as Windows, released at 20 November 1985.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        With this, we can make the judgement that it is worth talking about ‘Code Maintenance’.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Success of a software system relies on many factors. Out of which, Software Source Code plays an important role.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Though ‘Code Maintenance’ is a standard and people have it, in their software product presentations. It looks nice and beautiful.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        <b>But, you might have experienced it yourself, that, there is no place for ‘Maintenance’ when the actual coding starts.</b> And, that’s so common to ignore because it is clearly shown in the software life-cycle as the last stage.
        </Typography>
        <Box item xs={12} md={4}>
          <img src={LifeCycleImg} alt="Clean Code" style={{ width: "80%" }}/>
        </Box>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        So, by the time maintenance starts, most likely, I won’t be there to be part of it.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Products such as iPhone or Windows are still booming the market because of their maintenance strategies. And, also because they didn’t keep on re-writing their entire software code. Rather, maintaining whatever was there.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Lets understand this with the help of the above example of “grouping books”. How a poorly written software code will be of no use after some time.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Rather than using separate libraries or utilities if I simply put everything into one method. This will definitely work, but for a limited time. Now, as time goes source code would also be required to be updated or maintained.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Since, everything is cluttered like the books in the above example. It will be almost impossible to provide updates to the same source code. If one wants to update Log functions and file writing utilities, since it is not separated from the main operation, you might end up throwing and re-writing the same after some time.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        This mistake is so horrendous that even if you spend years in writing your source code. It will be of no use at some point.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Where as, had it been a separate class/utility. It can be fairly easy to modify and all the methods utilizing this will automatically adhere to it.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        History has already shown us that many good companies just got vanished because of the poorly written software code.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        If we take care of all the above aspects, it can be fairly easy for people to maintain it as well. It will be rare or impossible to keep the same person engaged who has initially build it.
        </Typography>
        <Typography variant="h3" sx={textPadding}>
        Conclusion
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>
        You might agree to the fact that majority of the successful software systems have surrounded us since substantially long time, such as iPhone released at June 29, 2007 and even some of them are around since generations such as Windows, released at 20 November 1985.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        There can be many such tutorials or ideas being shared by different people as per their knowledge and expertise. But, if I tell you to keep all this aside including whatever I just shared above.
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>
        Since you are here, I assume you have already qualified the test of patience. Let me take you back to the title, I am revealing the secret of “writing clean code in one statement”.
        </Typography>       
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        The only statement which is ultimately going to work is this.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        “a mindset, a mindset as if someone else is going to maintain your code”
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        This is the key.
        Even before you say ‘no’, I would suggest you to try this out for few days.
        Share your comments about your trial, I would love to hear!
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        I was lucky to know this secret in the very beginning of my industrial career.
        This key has kept me among the best coders in the companies I worked.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        The mindset has not only helped me to write beautiful software code but also pushed me to be helpful and co-operative in a team.
        </Typography>
        <Typography variant="inherit" color={defaultStyle.textGrey} sx={textPadding}>
        Don’t miss to subscribe, I will be sharing more contents on software programming practices.
        </Typography>
        <Typography variant="h6" color={defaultStyle.textGrey} sx={textPadding}>
        If you haven’t tried the ‘Code Generator’ as yet, I would suggest you do. It not only gives you a source code which is properly formatted or clean code in a manner, but also helps you save your time in writing so much of source code. Click here for free download.
        </Typography>
      </Box>
      </div>
  )}

export default CleanCodeInfo
