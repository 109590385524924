import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { defaultStyle } from "../../config/Config";

Button.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  IsActive: PropTypes.bool,
  IsCancel: PropTypes.bool,
  IsChange: PropTypes.bool,
  IsBlack: PropTypes.bool,
  sx: PropTypes.any,
};
Button.defaultProps = {
  handleClick: () => {},
};
export default function Button({
  title,
  isLoading,
  type,
  handleClick,
  disabled,
  sx,
  IsActive,
  IsCancel,
  IsChange,
  IsBlack,
}) {
  const style = {
    color: defaultStyle.buttonTextColor,
    bgcolor: defaultStyle.buttonColor,
    borderColor: defaultStyle.buttonColor,
    ":hover": {
      borderColor: defaultStyle.buttonColor,
      bgcolor: defaultStyle.secondaryHoverColor,
      color: defaultStyle.buttonTextColor,
    },
  };
  const styleCancel = {
    color: defaultStyle.cancelBlack,
    bgcolor: defaultStyle.whiteBackground,
    borderColor: defaultStyle.cancelBlack,
    ":hover": {
      borderColor: defaultStyle.cancelBlack,
      bgcolor: defaultStyle.cancelBackgroundHover,
      color: defaultStyle.cancelBlack,
    },
  };
  const styleChange = {
    color: defaultStyle.synstaoOrangeColor,
    bgcolor: defaultStyle.whiteBackground,
    borderColor: defaultStyle.synstaoOrangeColor,
    ":hover": {
      borderColor: defaultStyle.synstaoOrangeColor,
      bgcolor: defaultStyle.synstaoOrangeLightColor,
      color: defaultStyle.synstaoOrangeColor,
    },
  };
  const styleBlack = {
    color: defaultStyle.whiteBackground,
    bgcolor: defaultStyle.defaultColor,
    borderColor: defaultStyle.defaultColor,
    ":hover": {
      borderColor: defaultStyle.defaultColor,
      bgcolor: defaultStyle.blackColorLight,
      color: defaultStyle.whiteBackground,
    },
  };
  const defaultStyles = !IsActive ? style : null;
  const defaultStylesCancel = IsCancel ? styleCancel : null;
  const defaultStylesChange = IsChange ? styleChange : null;
  const defaultStylesBlack = IsBlack ? styleBlack : null;

  return (
    <LoadingButton
      sx={{
        ...sx,
        ...defaultStyles,
        ...defaultStylesCancel,
        ...defaultStylesChange,
        ...defaultStylesBlack,
        height: "34px",
        fontSize: "14px",
        padding: "4px 12px",
      }}
      disabled={disabled}
      loading={isLoading}
      size="small"
      type={type}
      variant="outlined"
      onClick={() => handleClick()}
    >
      {title}
    </LoadingButton>
  );
}
