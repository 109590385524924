import React from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '../../../components';
import { useForm } from 'react-hook-form';
import {
  Stack,
  Grid,
  Box,
  Typography,
  CardContent,
  Card,
} from '@mui/material';
import { TextFields } from '../../../components';
import { FormProvider as Form } from '../../../components/form/FormProvider';
import { publicPaths } from '../../../routes/path';
import googleBtn from '../../../assets/googleBtn.webp';
import facebookBtn from '../../../assets/facebookBtn.webp';

const tryFreeForm = () => {
  const navigate = useNavigate();
  const defaultValues = {
    fullName: '',
    email: '',
    password: '',
    confirmpassword: '',
  };
  const registerSchema = Yup.object().shape({
    fullName: Yup.string().required('FirstName is required'),
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmpassword: Yup.string().required('Confirm Password is required'),
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(registerSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;
  const handleTryFree = () => {
    navigate(publicPaths.pricing);
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: '100vh',
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Card elevation={3} sx={{ padding: '5px' }}>
          <CardContent>
            <Form methods={methods} onSubmit={handleSubmit(handleTryFree)}>
              <Typography textAlign="center" variant="subtitle1">sign up with</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  margin: '20px 0',
                }}
              >
                <img
                  src={googleBtn}
                  alt="Google Button"
                  style={{ width: '20%', margin: '20px 50px'}}
                />
                <img
                  src={facebookBtn}
                  alt="Facebook Button"
                  style={{ width: '20%', margin: '20px 50px' }}
                />
              </Box>
              <Typography textAlign="center" variant="subtitle2">
                Or sign up with credentials
              </Typography>
              <Stack spacing={1.5} margin={1}>
                <TextFields
                  item
                  sx={{ padding: '3px 3px', borderRadius: '7px' }}
                  name="fullName"
                  label="FirstName"
                  type="text"
                />
                <TextFields
                  sx={{ padding: '3px 3px', borderRadius: '7px' }}
                  name="email"
                  label="Email"
                  type="text"
                />
                <TextFields
                  sx={{ padding: '3px 3px', borderRadius: '7px' }}
                  name="password"
                  label="Password"
                  type="password"
                />
                <TextFields
                  sx={{ padding: '3px 3px', borderRadius: '7px' }}
                  name="confirmpassword"
                  label="Confirm Password"
                  type="password"
                />

                <LoadingButton
                  title={'Create Account'}
                  type="submit"
                  sx={{
                    padding: '8px',
                    borderRadius: '5px !important',
                  }}
                />
              </Stack>
            </Form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default tryFreeForm;
