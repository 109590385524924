import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { defaultButtonStyle } from "../../config/Config";

CustomeButton.propTypes = {
  handleClick: PropTypes.func,
  Content: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  error: PropTypes.bool,
  Icon: PropTypes.any,
  EndIcon: PropTypes.any,
  IsActive: PropTypes.bool,
  color: PropTypes.any,
};
export default function CustomeButton({
  handleClick = () => {},
  Content,
  disabled,
  sx,
  error,
  Icon,
  EndIcon,
  IsActive,
  color,
}) {
  const style = !IsActive ? defaultButtonStyle : null;
  return (
    <Button
      onClick={(e) => handleClick(e)}
      variant="outlined"
      size="small"
      disabled={disabled}
      color={error ? "error" : color}
      sx={{
        ...sx,
        ...style,
      }}
      startIcon={Icon}
      endIcon={EndIcon}
    >
      {Content}
    </Button>
  );
}
