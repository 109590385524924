import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { defaultStyle } from "../../config/Config";

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#999999",
      borderWidth: "1px",
      borderRadius: "5px",
    },
    "& input": {
      padding: "6px",
    },
  },
});

ModalTextfield.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  InputLabelProps: PropTypes.string,
  onDataChange: PropTypes.func,
  sx: PropTypes.object,
  IsBlackBorder: PropTypes.bool,
  disabled: PropTypes.string,
  value: PropTypes.string,
};
export default function ModalTextfield({
  name,
  type,
  InputLabelProps,
  onDataChange,
  sx,
  value,
  disabled,
  ...other
}) {
  const { control, register } = useFormContext();
  const StyledTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#999999",
        borderWidth: "1px",
        borderRadius: "5px",
      },
      "& input": {
        padding: "6px",
      },
    },
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          size="small"
          color="primary"
          {...field}
          type={type}
          value={value}
          disabled={disabled}
          fullWidth
          autoComplete="current-password"
          error={!!error}
          {...register(name, {
            onBlur: (e) => {
              if (onDataChange) {
                onDataChange(e);
              }
            },
          })}
          helperText={error?.message}
          InputLabelProps={InputLabelProps}
          InputProps={{
            sx: {
              ...sx,

              backgroundColor: defaultStyle.whiteBackground,
              color: defaultStyle.defaultColor,
              fontSize: "14px",
              padding: "4px 14px 4px 0",
              height: "2.5rem",
            },
          }}
          {...other}
        />
      )}
    />
  );
}
